// Your base variable overrides go here, e.g.:

// #region - Border
$theme-colors: (
  // This color comes from darkly's $purple
  "help": #6f42c1
);

$input-border-radius: 1.25rem;
$input-border-radius-lg: 1.5rem;
$input-border-radius-sm: 1rem;
// #endregion - Border

@import "node_modules/bootswatch/dist/darkly/variables";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// When using base variables to set another variable it may need to go here.

// #region - Bootstrap table
$h1-font-size: $font-size-base * 1.75;
$h2-font-size: $font-size-base * 1.625;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.325;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$body-bg: $black;
$table-accent-bg: $gray-900;
$table-hover-bg: $gray-800;
// #endregion - Bootstrap table

// #region - Pagination
$pagination-bg: $primary;
$pagination-hover-bg: lighten($primary, 10%);
$pagination-active-bg: $pagination-hover-bg;
$pagination-disabled-bg: darken($primary, 15%);
// #endregion - Pagination

.dark-theme {
  @import "./bootstrap";
  @import "node_modules/bootswatch/dist/darkly/bootswatch";
  @import "./common-theme-customization";

  // #region - react-bootstrap-typeahead
  .rbt-token {
    background-color: #444;
    color: #fff;
  }

  .rbt-token-active {
    background-color: #375a7f;
  }

  .rbt-input-multi.focus {
    color: #444;
    border-color: #739ac2;
    box-shadow: 0 0 0 0.2rem rgba(55, 90, 127, 0.25);
  }
  // #endregion - react-bootstrap-typeahead

  // #region - react-grid-layout
  .react-resizable-handle {
    background-image: url(./img/react-resize-handle-dark.svg);
  }
  // #endregion - react-grid-layout

  // #region - react-date-range
  .rdrCalendarWrapper {
    background-color: #303030;
  }

  .rdrDateDisplayWrapper {
    background-color: #444;
  }

  .rdrDayNumber span {
    color: #fff;
  }

  .rdrDayPassive .rdrDayNumber span {
    color: #666;
  }

  .rdrNextPrevButton {
    background-color: #375a7f;
  }

  .rdrPprevButton i {
    border-color: transparent rgb(255, 255, 255) transparent transparent;
  }

  .rdrNextButton i {
    border-color: transparent transparent transparent rgb(255, 255, 255);
  }

  .rdrMonthPicker select,
  .rdrYearPicker select {
    background-color: #444;
    color: #fff;
  }
  // #endregion - react-date-range

  // #region - site specific
  legend {
    @include font-size(1.25rem);
  }

  .dealerAppVantageLogo_LightTheme {
    display: none;
  }

  .logo-light-theme {
    display: none;
  }

  @media print {
    .dealerAppVantageLogo_LightTheme {
      display: inline;
    }
    .dealerAppVantageLogo_DarkTheme {
      display: none;
    }
  }

  @import "./gs-table-card-view/gs-table-card-view-dark";
  // #endregion - site specific
}
