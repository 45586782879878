hr.divider {
  border-color: #870000;
  border-width: 15px;
  margin-top: 0;
  margin-bottom: 0;
}

:global(.dark-theme) .themButton {
  color: #ccc;

  &:hover {
    color: #fff;
  }
}

:global(.light-theme) .themButton {
  color: #333;

  &:hover {
    color: #000;
  }
}
