// Your variable overrides go here, e.g.:

// #region - Border
$theme-colors: (
  // This color comes from flatly's $purple
  "help": #6f42c1
);

$input-border-radius: 1.25rem;
$input-border-radius-lg: 1.5rem;
$input-border-radius-sm: 1rem;
// #endregion - Border

@import "node_modules/bootswatch/dist/flatly/variables";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// When using base variables to set another variable it may need to go here.

$h1-font-size: $font-size-base * 1.75;
$h2-font-size: $font-size-base * 1.625;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.325;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$table-hover-bg: rgba($black, 0.15);

// #region - Pagination
$pagination-bg: $primary;
$pagination-hover-bg: lighten($primary, 10%);
$pagination-active-bg: $pagination-hover-bg;
$pagination-disabled-bg: darken($primary, 15%);
// #endregion - Pagination

.light-theme {
  @import "./bootstrap";
  @import "node_modules/bootswatch/dist/flatly/bootswatch";
  @import "./common-theme-customization";

  // #region - react-bootstrap-typeahead
  .rbt-token {
    background-color: #95a5a6;
    color: #fff;
  }

  .rbt-token-active {
    background-color: #2c3e50;
  }

  .rbt-input-multi.focus {
    color: #7b8a8b;
    border-color: #597ea2;
    box-shadow: 0 0 0 0.2rem rgba(44, 62, 80, 0.25);
  }
  // #endregion - react-bootstrap-typeahead

  // #region - react-date-range
  .rdrDateDisplayWrapper {
    background-color: rgba(0, 0, 0, 0.03);
  }

  .rdrNextPrevButton {
    background-color: #375a7f;
  }

  .rdrPprevButton i {
    border-color: transparent rgb(255, 255, 255) transparent transparent;
  }

  .rdrNextButton i {
    border-color: transparent transparent transparent rgb(255, 255, 255);
  }
  // #endregion - react-date-range

  // #region - site specific
  legend {
    @include font-size(1.25rem);
  }

  .dealerAppVantageLogo_DarkTheme {
    display: none;
  }

  .logo-dark-theme {
    display: none;
  }

  @import "./gs-table-card-view/gs-table-card-view-light";

  @media print {
    .badge-dark {
      color: #000;
    }

    .table tr {
      border-color: #000;
    }
  }
  // #endregion - site specific
}
