.gs-table-card-view-container {
  border-left-style: solid;
  border-right-style: solid;
  border-left-width: 1px;
  border-right-width: 1px;
}

@media screen and (max-width: breakpoint-max("sm", $grid-breakpoints)) {
  .gs-table-card-view-container {
    border-left-width: 0;
    border-right-width: 0;
  }
  
  .gs-table-card-view {
    width: 100%;
  }

  .gs-table-card-view thead {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .gs-table-card-view tr {
    // #region - Copied from /node_modules/bootstrap/scss/_card.scss @.card
    position: relative;
    // display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    // background-color: $card-bg;
    background-clip: border-box;
    // border: $card-border-width solid $card-border-color;
    @include border-radius($card-border-radius);

    > hr {
      margin-right: 0;
      margin-left: 0;
    }

    > .list-group:first-child {
      .list-group-item:first-child {
        @include border-top-radius($card-border-radius);
      }
    }

    > .list-group:last-child {
      .list-group-item:last-child {
        @include border-bottom-radius($card-border-radius);
      }
    }
    // #endregion - Copied from /node_modules/bootstrap/scss/_card.scss @.card

    display: block;
    margin-bottom: 1.25rem;

    // &:hover {
    //   background-color: #f5f5f5;
    // }
  }

  .gs-table-card-view td {
    // #region - Copied from /node_modules/bootstrap/scss/_card.scss @.card-body
    // flex: 1 1 auto;
    padding: $card-spacer-x;
    color: $card-color;
    // #region - Copied from /node_modules/bootstrap/scss/_card.scss @.card-body

    min-height: 2.438rem;
    padding-bottom: 0.5rem;

    &:not(:first-child) {
      padding-top: 0.5rem;
    }

    display: block;

    &:first-child {
      border-top: 0;
    }

    &:last-child {
      padding-bottom: $card-spacer-x;
    }
  }

  .gs-table-card-view .gs-table-card-view-header + td {
    padding-top: $card-spacer-x;
    min-height: 2.438rem;
  }

  .gs-table-card-view td.gs-table-card-view-header {
    // #region - Copied from /node_modules/bootstrap/scss/_card.scss @.card-header
    padding: $card-spacer-y $card-spacer-x;
    margin-bottom: 0; // Removes the default margin-bottom of <hN>
    color: $card-cap-color;
    background-color: $card-cap-bg;
    border-bottom: $card-border-width solid $card-border-color;

    &:first-child {
      @include border-radius($card-inner-border-radius $card-inner-border-radius 0 0);
    }

    // + .list-group {
    //   .list-group-item:first-child {
    //     border-top: 0;
    //   }
    // }
    // #endregion - Copied from /node_modules/bootstrap/scss/_card.scss @.card-header

    border: $card-border-width;
  }

  .gs-table-card-view.table-hover tbody tr {
    background-color: $body-bg;

    &:hover {
      background-color: $body-bg;
    }
  }

  .gs-table-card-view.table-striped tbody tr:nth-of-type(odd) {
    background-color: $table-accent-bg;

    &:hover {
      background-color: $table-accent-bg;
    }
  }

  .gs-table-card-view td[data-label] {
    &::before {
      content: attr(data-label);
      font-weight: bold;
      padding-right: 0.5rem;
    }
  }

  .gs-table-card-view td[data-empty-text]:empty::after {
    content: attr(data-empty-text);
    color: $text-muted;
  }
}
