:global(.dark-theme) {
  .helpToggleButton {
    color: #ccc;

    &:hover {
      color: #fff;
    }
  }

  .helpPopoverExpanded :global(.popover) {
    z-index: 2;
  }
}

:global(.light-theme) {
  .helpToggleButton {
    color: #333;

    &:hover {
      color: #000;
    }
  }

  .helpPopoverExpanded :global(.popover) {
    z-index: 2;
  }
}
