@import "node_modules/bootswatch/dist/darkly/variables";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

.gs-table-card-view-container {
  border-left-color: $white;
  border-right-color: $white;
}

@media screen and (max-width: map-get($grid-breakpoints, "md")) {
  .gs-table-card-view tr {
    border: $card-border-width solid rgb(68, 68, 68);
  }
}
