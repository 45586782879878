@import "node_modules/bootswatch/dist/flatly/variables";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

.navigationDrawer {
  &:global(.card) {
    border-width: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    // From fixed-bottom
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }

  :global(.card-body) :global(nav) :global(.navbar-item) {
    width: 6.25rem;
    padding: 0.5rem;

    &.activeNavbarItem {
      background-color: #870000;
    }
  }
}

:global(.dark-theme) .navigationDrawer {
  :global(.navbar-nav) {
    :global(.nav-link) {
      color: rgba(255, 255, 255, 0.5);

      &:hover,
      &:focus {
        color: #fff;
      }

      &:global(.disabled) {
        color: rgba(255, 255, 255, 0.3);
      }
    }

    :global(.show) > :global(.nav-link),
    :global(.active) > :global(.nav-link),
    :global(.nav-link):global(.show),
    :global(.nav-link):global(.active) {
      color: #fff;
    }
  }
}

:global(.light-theme) .navigationDrawer {
  &:global(.card) {
    background-color: #ecf0f1;
  }

  :global(.navbar-nav) {
    :global(.nav-link) {
      color: rgba(0, 0, 0, 0.5);

      &:hover,
      &:focus {
        color: rgba(0, 0, 0, 0.7);
      }

      &:global(.disabled) {
        color: rgba(0, 0, 0, 0.3);
      }
    }

    :global(.show) > :global(.nav-link),
    :global(.active) > :global(.nav-link),
    :global(.nav-link):global(.show),
    :global(.nav-link):global(.active) {
      color: rgba(0, 0, 0, 0.9);
    }
  }
}

.siteNavigationMenuButton {
  background-color: $danger !important;
  border-color: $danger !important;
  border-radius: 50% !important;
  top: -20px;

  // HACK(jc): Couldn't center it for some reason.
  //   It was always a bit to the right.
  margin-left: -1.3rem !important;
}

// Bootstrap size: lg
@media screen and (min-width: 992px) {
  .navigationDrawer {
    &:global(.card) {
      background-color: rgba(0, 0, 0, 0);
      float: left;
      position: relative;
      width: 150px;
      // 103px is the height of the banner.
      min-height: calc(100vh - 103px);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    :global(.card-body) :global(nav) {
      // HACK(jc): To override react-pose's inline styles,
      //   !IMPORTANT had to be used.
      height: auto !important;

      :global(.navbar-item) {
        width: 100%;
        padding-top: 0.1rem;
        padding-right: 1.2rem;
        padding-bottom: 0.1rem;
        padding-left: 1.2rem;
        // HACK(jc): To override react-pose's inline styles,
        //   !IMPORTANT had to be used.
        opacity: 1 !important;
        transform: translateY(0px) translateZ(0px) !important;

        :global(svg) {
          margin-right: 0.5rem;
        }
      }
    }
  }
}

.navigationDrawerHelpPopover {
  :global(.popover) {
    z-index: 1031;
  }
}
