:global(.container).containerOuterWrap {
  padding-left: 0;
  padding-right: 0;
  // 103px is the height of the banner.
  min-height: calc(100vh - 103px);

  &.mainScreen {
    background-image: url(./img/main-background-highway-night.jpg);
    background-size: cover;
    background-position: center;
    height: 100%;

    .navigationWrapper {
      background-color: #303030;
    }
  }

  &.subScreen .navigationWrapper {
    background-color: #303030;
  }
}

.containerInnerWrap {
  padding-top: 1.5rem;
  padding-bottom: 4.5rem;
}

// Bootstrap size: lg
@media screen and (min-width: 992px) {
  :global(.container).containerOuterWrap {
    &.mainScreen {
      .navigationWrapper {
        background-color: rgba(0, 0, 0, 0);
        backdrop-filter: blur(10px);
      }
    }
  }

  @-moz-document url-prefix() {
    :global(.container).containerOuterWrap {
      &.mainScreen .navigationWrapper {
        // Firefox doesn't support `backdrop-filter: blur(10px);` above
        background-color: rgba(0, 0, 0, 0.25);
      }
    }
  }

  .containerInnerWrap {
    padding-bottom: 2rem;
  }
}
