@import "node_modules/react-bootstrap-typeahead/css/Typeahead.scss";

@import "./gs-table-card-view/gs-table-card-view";

& {
  // html {
  font-family: sans-serif; // 2
  line-height: 1.15; // 3
  -webkit-text-size-adjust: 100%; // 4
  -ms-text-size-adjust: 100%; // 4
  -ms-overflow-style: scrollbar; // 5
  -webkit-tap-highlight-color: rgba($black, 0); // 6
  // }
}

h1,
.h1 {
  font-weight: $font-weight-bold;
}

.btn {
  text-transform: capitalize;
}

// This fixes an issue when dragging a table row.
// .table tr {
//   background-color: $body-bg;
// }

// #region - react-date-range
.rdrCalendarWrapper {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.rdrDateDisplayWrapper {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.rdrDateInput:focus {
  color: #444;
  background-color: #fff;
  border-color: #739ac2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(55, 90, 127, 0.25);
}
// #endregion - react-date-range

// #region - Bootstrap
.bg-help > .popover {
  background-color: theme-color(help);

  .popover-header {
    color: color-yiq(theme-color(help));
    background-color: darken(theme-color(help), 5%);
    border-bottom-color: darken(theme-color(help), 5%);
  }

  &.bs-popover-top .popover-header::before,
  &.bs-popover-auto[x-placement^="top"] .popover-header::before {
    border-top-color: darken(theme-color(help), 5%);
  }

  &.bs-popover-bottom .popover-header::before,
  &.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    border-bottom-color: darken(theme-color(help), 5%);
  }

  &.bs-popover-right .popover-header::before,
  &.bs-popover-auto[x-placement^="right"] .popover-header::before {
    border-right-color: darken(theme-color(help), 5%);
  }

  &.bs-popover-left .popover-header::before,
  &.bs-popover-auto[x-placement^="left"] .popover-header::before {
    border-left-color: darken(theme-color(help), 5%);
  }

  .popover-body {
    color: color-yiq(theme-color(help));
  }

  &.bs-popover-top > .arrow::after,
  &.bs-popover-auto[x-placement^="top"] > .arrow::after {
    border-top-color: theme-color(help);
  }

  &.bs-popover-bottom > .arrow::after,
  &.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
    border-bottom-color: theme-color(help);
  }

  &.bs-popover-right > .arrow::after,
  &.bs-popover-auto[x-placement^="right"] > .arrow::after {
    border-right-color: theme-color(help);
  }

  &.bs-popover-left > .arrow::after,
  &.bs-popover-auto[x-placement^="left"] > .arrow::after {
    border-left-color: theme-color(help);
  }
}

.page-link:disabled {
  background-color: $pagination-hover-bg;
}

.table th {
  border-top: 0;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
  border-top-left-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text {
  border-top-right-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}

// #region - print
@media print {
  .navbar {
    display: flex;
  }

  body {
    color: #000;
    background-color: #fff !important;
  }

  .form-control {
    color: #000;
    border-color: #aaa;
  }

  .table {
    color: #000;
  }

  .table-hover tbody tr:hover {
    color: #000;
  }

  .custom-switch {
    padding-left: $form-check-input-gutter;

    .custom-control-label {
      &::before {
        display: none;
      }

      &::after {
        display: none;
      }
    }
  }

  .custom-control-input {
    opacity: 1;
    top: calc(
      #{(
          ($font-size-base * $line-height-base - $custom-control-indicator-size) /
            2
        )} + #{$custom-control-indicator-border-width * 2}
    );
    left: 0;
  }
}
// #endregion - print
// #endregion - Bootstrap

// #region - react-bootstrap-typeahead
.rbt-highlight-text {
  text-decoration: underline;
}

.rbt-token {
  padding-left: 0.6em;
  border-radius: 10rem;
}
// #endregion - react-bootstrap-typeahead

// #region - site specific
& {
  min-width: 320px;
  min-height: 320px;
}

body {
  min-width: 320px;
  min-height: 320px;
}

h1 {
  margin-bottom: 1.3rem;
}

#mainContent:focus {
  outline: none;
}

.help-popover {
  cursor: pointer;
}

.popover {
  // The sliding navbar needs to appear above other popovers.
  z-index: 1;
}

.sortable {
  text-transform: capitalize;
  white-space: nowrap;
  cursor: pointer;
}

.shadow-up {
  box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.table-button {
  border-radius: 50%;
  height: 2.15rem;
  width: 2.15rem;
}
// #endregion - site specific

// Bootstrap size: sm
@media screen and (min-width: 576px) {
  // #region - Bootstrap
  .input-group > .input-group-prepend > .btn,
  .input-group > .input-group-prepend > .input-group-text {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .input-group > .input-group-append > .btn,
  .input-group > .input-group-append > .input-group-text {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  // #endregion - Bootstrap
}

// Bootstrap size: lg
@media screen and (min-width: 992px) {
  // #region - Bootstrap

  .input-group > .input-group-prepend > .btn,
  .input-group > .input-group-prepend > .input-group-text {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .input-group > .input-group-append > .btn,
  .input-group > .input-group-append > .input-group-text {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }
  // #endregion - Bootstrap
}
